import React, { useState, useCallback, createContext } from "react";
import { createMedia } from "@artsy/fresnel";
import {
  useRadio
} from '@chakra-ui/react';
import { v4 as uuidv4 } from "uuid";
import useResizeObserver from '@react-hook/resize-observer'

import * as Fa from 'react-icons/fa';

export const BodyWidthContext = createContext(0);

const ExampleAppMedia = createMedia({
  breakpoints: {
    xs: 0, // mobile
    sm: 768, // tablet
    sidebar: 992, // graph sidebar
    md: 1000, // computer
    lg: 1200,
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = ExampleAppMedia

export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const useClientRect = () => {
  const [rect, setRect] = useState({ width: 0, height: 0 });
  const ref = useCallback(node => {
    if (node !== null) {
      const { width, height } = node.getBoundingClientRect();
      setRect({ width, height });
    }
  }, []);
  return [rect, ref];
};

export const useSize = (target) => {
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  React.useEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect())
    }
  }, [target.current])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
};

// export function isDarkMode() {
//   if (typeof window !== 'undefined') {
//     const supportsColorScheme = window.matchMedia('(prefers-color-scheme)').matches;
//     const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
//     const isLightMode = window.matchMedia('(prefers-color-scheme: light)').matches;

//     return (supportsColorScheme && isDarkMode);
//   }
//   return false
// }

export const darkModeContext = React.createContext(false)

export const defaultFavoriteLists = [
  {
    id: -1,
    position: 0,
    name: 'Bookmarks',
    icon: 'bookmark',
    color: 'blue',
    hide: false,
    codes: []
  }
]

export const favoritesIcons = {
  star: Fa.FaStar,
  flag: Fa.FaFlag,
  heart: Fa.FaHeart,
  exclamation: Fa.FaExclamation,
  tree: Fa.FaTree,
  paperclip: Fa.FaPaperclip,
  thumbtack: Fa.FaThumbtack,
  fire: Fa.FaFire,
  seedling: Fa.FaSeedling,
  gift: Fa.FaGift,
  'paper plane': Fa.FaPaperPlane,
  atom: Fa.FaAtom,
  lightbulb: Fa.FaLightbulb,
  wallet: Fa.FaWallet,
  gem: Fa.FaGem,
  bookmark: Fa.FaBookmark,
}

export const favoritesColors = [
  'red', 'orange', 'yellow', 'lime', 'green', 'teal', 'cyan', 'blue', 'indigo', 'purple', 'violet', 'brown'
]
