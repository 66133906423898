import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_CREATED,
  AUTH_VERIFIED,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_VERIFY,
  AUTH_PASSWORD_VERIFIED,
  AUTH_PASSWORD_CHANGE,
  AUTH_EMAIL_CHANGE,
  AUTH_EMAIL_VERIFY,
  CLEAR_ERR
} from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  account: null,
  error: null,
  loading: false,
  created: null,
  verified: null,
  password_reset: null,
  email_change_status: null,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    account: action.account,
    error: null,
    loading: false
  });
};

const authCreated = (state, action) => {
  return updateObject(state, {
    created: true,
    error: null,
    loading: false
  })
}

const authVerified = (state, action) => {
  return updateObject(state, {
    verified: true,
    error: null,
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: 'unauthenticated',
    account: null,
    error: null,
    loading: false,
  });
};

const authPasswordReset = (state, action) => {
  return updateObject(state, {
    password_reset: 'submitted',
    error: null,
    loading: false,
  })
}

const authPasswordVerify = (state, action) => {
  return updateObject(state, {
    password_reset: 'code_verified',
    error: null,
    loading: false,
  })
}

const authPasswordVerified = (state, action) => {
  return updateObject(state, {
    password_reset: 'password_reset',
    error: null,
    loading: false,
  })
}

const authPasswordChange = (state, action) => {
  return updateObject(state, {
    password_reset: 'password_change',
    error: null,
    loading: false,
  })
}
const authEmailChange = (state, action) => {
  return updateObject(state, {
    email_change_status: 'email_sent',
    error: null,
    loading: false,
  })
}

const authEmailChangeVerify = (state, action) => {
  return updateObject(state, {
    email_change_status: 'email_changed',
    error: null,
    loading: false,
  })
}

const authClear = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    created: null,
    verified: null,
    email_change_status: null,
    password_reset: null
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return authStart(state, action);
    case AUTH_SUCCESS:
      return authSuccess(state, action);
    case AUTH_CREATED:
      return authCreated(state, action);
    case AUTH_VERIFIED:
      return authVerified(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_LOGOUT:
      return authLogout(state, action);
    case AUTH_PASSWORD_RESET:
      return authPasswordReset(state, action);
    case AUTH_PASSWORD_VERIFY:
      return authPasswordVerify(state, action);
    case AUTH_PASSWORD_VERIFIED:
      return authPasswordVerified(state, action);
    case AUTH_PASSWORD_CHANGE:
      return authPasswordChange(state, action);
    case AUTH_EMAIL_CHANGE:
      return authEmailChange(state, action);
    case AUTH_EMAIL_VERIFY:
      return authEmailChangeVerify(state, action);
    case CLEAR_ERR:
      return authClear(state, action);
    default: {
      return state;
    }
  }
};

export default reducer;
