import {
  STRIPE_START,
  STRIPE_SUCCESS,
  STRIPE_USER_VERIFIED,
  STRIPE_SUBSCRIPTIONS,
  STRIPE_SUB_UPDATE_PREVIEW,
  STRIPE_SUB_UPDATE,
  STRIPE_CARD_UPDATE,
  STRIPE_FAIL,
  STRIPE_CLEAR
} from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  success: false,
  userVerified: null,
  error: null,
  loading: false,
  data: null,
  subscriptions: null,
  cardUpdate: null,
  subUpdatePreview: null,
  subUpdate: null,
};

const stripeStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: true,
    data: null,
  });
};

const stripeSuccess = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    data: action.data,
  });
};

const stripeUserVerified = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    userVerified: action.data,
  })
}

const stripeSubscriptions = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subscriptions: action.data,
  })
}

const stripeSubUpdatePreview = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subUpdatePreview: action.data.preview,
  })
}

const stripeSubUpdate = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subUpdate: action.data,
  })
}

const stripeCardUpdate = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    cardUpdate: action.data,
  })
}

const stripeFail = (state, action) => {
  return updateObject(state, {
    success: false,
    error: action.error?.response?.data,
    loading: false,
    data: null,
  });
};

const stripeClear = (state, action) => {
  return updateObject(state, {
    success: false,
    error: null,
    loading: false,
    data: null,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STRIPE_START:
      return stripeStart(state, action);
    case STRIPE_SUCCESS:
      return stripeSuccess(state, action);
    case STRIPE_USER_VERIFIED:
      return stripeUserVerified(state, action);
    case STRIPE_SUBSCRIPTIONS:
      return stripeSubscriptions(state, action);
    case STRIPE_SUB_UPDATE_PREVIEW:
      return stripeSubUpdatePreview(state, action);
    case STRIPE_SUB_UPDATE:
      return stripeSubUpdate(state, action);
    case STRIPE_CARD_UPDATE:
      return stripeCardUpdate(state, action);
    case STRIPE_FAIL:
      return stripeFail(state, action);
    case STRIPE_CLEAR:
      return stripeClear(state, action);
    default:
      return state;
  }
};

export default reducer;
