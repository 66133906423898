// theme.js

// 1. import `extendTheme` function
import { extendTheme } from "@chakra-ui/react"

// 2. Add your color mode config
const config = {
    initialColorMode: "light",
    useSystemColorMode: typeof window !== "undefined" &&
        'chakra-ui-theme-use-system-color-mode' in window.localStorage &&
        JSON.parse(window.localStorage['chakra-ui-theme-use-system-color-mode']) || false,
    components: {
    },
    colors: {
        ako_12: {
            200: '#d01919',
            500: '#d01919',
        },
        ako_9: {
            200: '#f26202',
            500: '#f26202',
        },
        ako_6: {
            200: '#eaae00',
            500: '#eaae00',
        },
        ako_3: {
            200: '#16ab39',
            500: '#16ab39',
        },
        ako_1: {
            200: '#009c95',
            500: '#009c95',
        },
        rko_12: {
            200: '#a7bd0d',
            500: '#a7bd0d',
        },
        rko_9: {
            200: '#5829bb',
            500: '#5829bb',
        },
        rko_6: {
            200: '#e61a8d',
            500: '#e61a8d',
        },
        rko_3: {
            200: '#975b33',
            500: '#975b33',
        },
        rko_1: {
            200: '#9627ba',
            500: '#9627ba',
        },
        charcoal: "#1c1c1c",
        bgLight: "#f7f7f7",
        bgDark: "#1c1c1c",
    }
}

const colors = {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    gray: {
        50: '#f9f9fa',
        100: '#ebeef0',
        200: '#dde1e5',
        300: '#ced4d8',
        400: '#bdc5cb',
        500: '#aab4bd',
        600: '#95a1ac',
        700: '#7b8b98',
        800: '#5f6e7a',
        900: '#384047'
    },
    blue: {
        50: '#f5faff',
        100: '#def0ff',
        200: '#c6e4ff',
        300: '#abd8ff',
        400: '#8dcaff',
        500: '#69b9ff',
        600: '#3da5ff',
        700: '#0189ff',
        800: '#006cca',
        900: '#004078'
    },
    indigo: {
        50: '#f9f9ff',
        100: '#ebecff',
        200: '#dddeff',
        300: '#cecfff',
        400: '#bcbeff',
        500: '#a9acff',
        600: '#9296ff',
        700: '#767aff',
        800: '#4d53ff',
        900: '#0007d6',
    },
    violet: {
        50: '#fbf8ff',
        100: '#f4eaff',
        200: '#ebdaff',
        300: '#e2c9ff',
        400: '#d8b5ff',
        500: '#cc9fff',
        600: '#be85ff',
        700: '#ac63ff',
        800: '#8f2eff',
        900: '#5300b1',
    },
    fuschia: {
        50: '#fff7ff',
        100: '#fee6ff',
        200: '#fdd3ff',
        300: '#fdbeff',
        400: '#fca6ff',
        500: '#fb87ff',
        600: '#f95cff',
        700: '#ef00f7',
        800: '#be00c5',
        900: '#730077',
    },
    pink: {
        50: '#fff7fb',
        100: '#ffe7f4',
        200: '#ffd5ec',
        300: '#ffc1e2',
        400: '#ffaad7',
        500: '#ff8eca',
        600: '#ff6ab9',
        700: '#ff269a',
        800: '#d30070',
        900: '#800044',
    },
    red: {
        50: '#fff8f8',
        100: '#ffe8e9',
        200: '#ffd7d9',
        300: '#ffc4c6',
        400: '#ffafb1',
        500: '#ff9598',
        600: '#ff7478',
        700: '#ff3d43',
        800: '#db0007',
        900: '#850004',
    },
    orange: {
        50: '#fff8f2',
        100: '#ffead8',
        200: '#ffdaba',
        300: '#ffc898',
        400: '#ffb472',
        500: '#ff9b43',
        600: '#ff7904',
        700: '#dd6700',
        800: '#ae5100',
        900: '#673000',
    },
    yellow: {
        50: '#fffdbb',
        100: '#fbf400',
        200: '#eee600',
        300: '#dfd800',
        400: '#cfc900',
        500: '#beb800',
        600: '#a9a400',
        700: '#918d00',
        800: '#736f00',
        900: '#434100',
    },
    lime: {
        50: '#efffdc',
        100: '#c5ff82',
        200: '#87fc00',
        300: '#7eed00',
        400: '#75dc00',
        500: '#6bc900',
        600: '#60b400',
        700: '#529a00',
        800: '#417a00',
        900: '#264800',
    },
    green: {
        50: '#eaffea',
        100: '#b8ffb5',
        200: '#63ff5e',
        300: '#08f400',
        400: '#07e300',
        500: '#07d000',
        600: '#06ba00',
        700: '#059f00',
        800: '#047e00',
        900: '#024b00',
    },
    teal: {
        50: '#e7fff2',
        100: '#acffd3',
        200: '#42ff9a',
        300: '#00f371',
        400: '#00e169',
        500: '#00ce60',
        600: '#00b856',
        700: '#009e4a',
        800: '#007d3a',
        900: '#004a22',
    },
    cyan: {
        50: '#e3fffe',
        100: '#96fffc',
        200: '#00faf2',
        300: '#00ebe4',
        400: '#00dad3',
        500: '#00c8c1',
        600: '#00b2ad',
        700: '#009994',
        800: '#007975',
        900: '#004745',
    },
}

// 3. extend the theme
const theme = extendTheme(config)

export default theme
