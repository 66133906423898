import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PROFILE_CREATE_FAVORITES,
  PROFILE_PATCH_FAVORITES,
  PROFILE_CLEAR
} from "../actions/actionTypes";
import { updateObject } from "../utility";
import update from 'immutability-helper';

const initialState = {
  userProfile: null,
  success: false,
  error: null,
  loading: false,
};

const profileStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: true,
  });
};

const profileSuccess = (state, action, patch = null) => {
  let userProfile = {
    subscription: action.data.subscription,
    free_trial_end_date: action.data.free_trial_end_date,
    paid_until: action.data.paid_until,
    is_paused: action.data.is_paused,
    pause_until: action.data.pause_until,
    favorites: action.data.favorites,
    crypto_favorites: action.data.crypto_favorites,
    advancedTableFilters: {
      mcl: action.data.mcl,
      mch: action.data.mch,
      mc: action.data.mc,
      vll: action.data.vll,
      vlh: action.data.vlh,
      vl: action.data.vl,
      prl: action.data.prl,
      prh: action.data.prh,
      pr: action.data.pr,
    },
    cryptoTableFilters: {
      crypto_vll: action.data.crypto_vll,
      crypto_vlh: action.data.crypto_vlh,
      crypto_vl: action.data.crypto_vl,
      crypto_prl: action.data.crypto_prl,
      crypto_prh: action.data.crypto_prh,
      crypto_pr: action.data.crypto_pr,
    }
  }
  return updateObject(state, {
    userProfile: userProfile,
    success: true,
    loading: false,
  });
};

const profileCreateFavorites = (state, action) => {
  return updateObject(state, {
    success: true,
    loading: false,
    userProfile: updateObject(state.userProfile, action.data.exchange === 'momentum' ? {
      favorites: [...state.userProfile.favorites, action.data.data]
    } : {
      crypto_favorites: [...state.userProfile.crypto_favorites, action.data.data]
    })
  });
};

const profilePatchFavorites = (state, action) => {
  const favorites = action.data.exchange === 'momentum' ? state.userProfile.favorites : state.userProfile.crypto_favorites;
  const updatedFavorite = favorites.findIndex(favorite => favorite.id === action.data.data.id);


  return updateObject(state, {
    success: true,
    loading: false,
    userProfile: updateObject(state.userProfile, action.data.exchange === 'momentum' ? {
      favorites: update(favorites, { [updatedFavorite]: { $set: action.data.data } })
    } : {
      crypto_favorites: update(favorites, { [updatedFavorite]: { $set: action.data.data } })
    })
  })
}

const profileFail = (state, action) => {
  return updateObject(state, {
    success: false,
    error: action.error,
    loading: false,
  });
};

const profileClear = (state, action) => {
  return updateObject(state, initialState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_START:
      return profileStart(state, action);
    case PROFILE_SUCCESS:
      return profileSuccess(state, action);
    case PROFILE_FAIL:
      return profileFail(state, action);
    case PROFILE_CREATE_FAVORITES:
      return profileCreateFavorites(state, action);
    case PROFILE_PATCH_FAVORITES:
      return profilePatchFavorites(state, action);
    case PROFILE_CLEAR:
      return profileClear(state, action);
    default:
      return state;
  }
};

export default reducer;
