// import '../styles/fomantic/semantic.min.css';
import '../styles/globals.css'
import '../styles/chart.css'
import { StrictMode, useEffect } from 'react';
import { useRouter } from 'next/router'
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { MediaContextProvider } from "../components/media";
import { host } from "../components/constants";

import { ChakraProvider } from "@chakra-ui/react";
// import { Chakra } from '../components/Chakra';
import theme from "../components/theme";

import authReducer from "../store/reducers/auth";
import profileReducer from "../store/reducers/profile";
import stockReducer from "../store/reducers/stock";
import contactReducer from "../store/reducers/contact";
import couponReducer from "../store/reducers/coupon";
import stripeReducer from "../store/reducers/stripe";

import { init, push } from "../components/matomo/matomo-next";


// const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhances = compose;

const rootReducer = combineReducers({
  auth: authReducer,
  userProfile: profileReducer,
  stock: stockReducer,
  contact: contactReducer,
  coupon: couponReducer,
  stripe: stripeReducer,
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

function MyApp({ Component, pageProps, err }) {

  useEffect(() => {
    init({
      url: host,
      siteId: 1,
      jsTrackerFile: 'js/',
      phpTrackerFile: 'js/'
    })

    push(["setCookieDomain", "*.momentumscreener.com"]);
    push(["setDomains", "*.momentumscreener.com"]);
    push(['enableHeartBeatTimer']);

  }, [])

  return (
    <StrictMode>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <MediaContextProvider>
            <Component {...pageProps} err={err} />
          </MediaContextProvider>
        </ChakraProvider>
      </Provider>
    </StrictMode>
  )
}

export default MyApp;
