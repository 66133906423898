import {
  COUPON_START,
  COUPON_SUCCESS,
  COUPON_FAIL,
  COUPON_CLEAR
} from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  coupon: null,
  percentOff: null,
  dollarOff: null,
  percentOrDollar: null,
  validFor: [],
  success: false,
  error: null,
  loading: false,
};

const couponStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: true
  });
};

const couponSuccess = (state, action) => {
  return updateObject(state, {
    coupon: action.data.coupon,
    percentOff: action.data.percentOff,
    dollarOff: action.data.dollarOff,
    percentOrDollar: action.data.percentOrDollar, // percent = False, dollar = True
    validFor: action.data.validFor,
    success: true,
    error: null,
    loading: false,
  });
};

const couponFail = (state, action) => {
  return updateObject(state, {
    coupon: null,
    percentOff: null,
    dollarOff: null,
    percentOrDollar: null,
    validFor: [],
    success: false,
    error: action.error.response.data,
    loading: false,
  });
};

const couponClear = (state, action) => {
  return initialState
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUPON_START:
      return couponStart(state, action);
    case COUPON_SUCCESS:
      return couponSuccess(state, action);
    case COUPON_FAIL:
      return couponFail(state, action);
    case COUPON_CLEAR:
      return couponClear(state, action);
    default:
      return state;
  }
};

export default reducer;
