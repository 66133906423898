import {
  CONTACT_START,
  CONTACT_SUCCESS,
  CONTACT_FAIL,
  CONTACT_CLEAR,
} from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  error: null,
  success: false,
  loading: false,
};

const contactStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: true
  });
};

const contactSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    success: true,
    loading: false
  });
};

const contactFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    success: false,
    loading: false
  });
};

const contactClear = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_START:
      return contactStart(state, action);
    case CONTACT_SUCCESS:
      return contactSuccess(state, action);
    case CONTACT_FAIL:
      return contactFail(state, action);
    case CONTACT_CLEAR:
      return contactClear(state, action);
    default:
      return state;
  }
};

export default reducer;
