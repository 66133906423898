// Store url strings and other constants that might be used 
// across multiple files here

export const host = process.env.NEXT_PUBLIC_HOST;
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
export const STRIPE_PRICE_ESSM = process.env.NEXT_PUBLIC_STRIPE_PRICE_ESSM;
export const STRIPE_PRICE_ESSY = process.env.NEXT_PUBLIC_STRIPE_PRICE_ESSY;
export const STRIPE_PRICE_ADVM = process.env.NEXT_PUBLIC_STRIPE_PRICE_ADVM;
export const STRIPE_PRICE_ADVY = process.env.NEXT_PUBLIC_STRIPE_PRICE_ADVY;
export const STRIPE_PRICE_ESSM_OLD = process.env.NEXT_PUBLIC_STRIPE_PRICE_ESSM_OLD;
export const STRIPE_PRICE_ESSY_OLD = process.env.NEXT_PUBLIC_STRIPE_PRICE_ESSY_OLD;
export const STRIPE_PRICE_ADVM_OLD = process.env.NEXT_PUBLIC_STRIPE_PRICE_ADVM_OLD;
export const STRIPE_PRICE_ADVY_OLD = process.env.NEXT_PUBLIC_STRIPE_PRICE_ADVY_OLD;

export const apiURL = 'api/';
export const stockAPIURL = 'stockAPI/';
export const cryptoAPIURL = 'cryptoAPI/';
export const rootURL = '';

export const accountEndpoint = `${host}${apiURL}`;
export const stockEndpoint = `${host}${stockAPIURL}`;
export const cryptoEndpoint = `${host}${cryptoAPIURL}`;

export const profileURL = `${accountEndpoint}profile/`;
export const favoritesURL = `${profileURL}favorites/`;
export const cryptoFavoritesURL = `${profileURL}cryptoFavorites/`;
export const registerURL = `${accountEndpoint}register/`;
export const registerVerifyURL = `${registerURL}verify/`;
export const loginURL = `${accountEndpoint}login/`;
export const logoutURL = `${accountEndpoint}logout/`;
export const logoutAllURL = `${accountEndpoint}lougoutall/`;
export const passwordURL = `${accountEndpoint}password/`;
export const passwordResetURL = `${passwordURL}reset/`;
export const passwordResetVerifyURL = `${passwordResetURL}verify/`;
export const passwordResetVerifiedURL = `${passwordResetURL}verified/`;
export const passwordChangeURL = `${passwordURL}change/`;
export const emailChangeURL = `${accountEndpoint}email/change/`;
export const emailChangeVerifyURL = `${emailChangeURL}verify/`;
export const userMeURL = `${accountEndpoint}userMe/`;
export const createCheckoutSessionURL = `${accountEndpoint}createCheckoutSession/`;
export const createCustomerPortalSessionURL = `${accountEndpoint}createCustomerPortalSession/`;
export const customerPortalURL = `${accountEndpoint}customer-portal/`;
export const contactUsURL = `${accountEndpoint}contactUs/`;
export const checkCouponURL = `${accountEndpoint}validateCoupon/`;
export const submitPaymentURL = `${accountEndpoint}submitPayment/`;
export const createStripeUserURL = `${accountEndpoint}checkStripeUser/`;
export const createStripeSubscriptionURL = `${accountEndpoint}createStripeSubscription/`;
export const previewStripeSubscriptionURL = `${accountEndpoint}previewStripeSubscription/`;
export const updateStripeSubscriptionURL = `${accountEndpoint}updateStripeSubscription/`;
export const getSubscriptionsURL = `${accountEndpoint}subscriptions/`;
export const cancelSubscriptionURL = `${accountEndpoint}cancelSubscription/`;
export const resubscribeURL = `${accountEndpoint}resubscribe/`;
export const deleteAccountURL = `${accountEndpoint}deleteAccount`;
export const getCardUpdateSecretURL = `${accountEndpoint}getCardUpdateSecret/`;
export const setPaymentIDURL = `${accountEndpoint}setPaymentID/`;
export const pricesURL = `${accountEndpoint}prices/`;

export const stockHistURL = `${stockEndpoint}stockHist`;
export const newRowURL = `${stockEndpoint}newRow`;
export const stockProfileURL = `${stockEndpoint}stockProfile`;
export const verifyURL = `${stockEndpoint}verify`;
export const tableURL = `${stockEndpoint}table`;
export const allTickersURL = `${stockEndpoint}allTickers`;
export const updatedURL = `${stockEndpoint}updated`;
export const counttURL = `${stockEndpoint}count`;
export const compareURL = `${stockEndpoint}compare`;
export const searchURL = `${stockEndpoint}search`;
export const refreshStockURL = `${stockEndpoint}refreshStock`;
export const testURL = `${stockEndpoint}test`;
export const hideCodeURL = `${stockEndpoint}hideCode`;

export const cryptoCodeHistURL = `${cryptoEndpoint}codeHist`;
export const cryptoNewRowURL = `${cryptoEndpoint}newRow`;
export const cryptoCodeProfileURL = `${cryptoEndpoint}codeProfile`;
export const cryptoVerifyURL = `${cryptoEndpoint}verify`;
export const cryptoTableURL = `${cryptoEndpoint}table`;
export const cryptoAllCodesURL = `${cryptoEndpoint}allCryptoCodes`;
export const cryptoUpdatedURL = `${cryptoEndpoint}updated`;
export const cryptoCounttURL = `${cryptoEndpoint}count`;
export const cryptoCompareURL = `${cryptoEndpoint}compare`;
export const cryptoSearchURL = `${cryptoEndpoint}search`;
export const cryptoRefreshCodeURL = `${cryptoEndpoint}refreshCode`;
export const cryptoTestURL = `${cryptoEndpoint}test`;
export const cryptoHideCodeURL = `${cryptoEndpoint}hideCode`;

export const loginPageURL = `${rootURL}/login`
export const signupPageURL = `${rootURL}/signup`
export const signinPageURL = `${rootURL}/signin`
export const signupVerifiedPageURL = `${signupPageURL}/verified`
export const passwordResetPageURL = `${rootURL}/password/reset`
export const accountPageURL = `${rootURL}/account`
export const settingsPageURL = `${rootURL}/settings`
export const subscribePageURL = `${rootURL}/upgrade`
export const successPageURL = `${rootURL}/success`
export const dividendchartPageURL = `${rootURL}/dividend`
export const momentumchartPageURL = `${rootURL}/momentum`
export const historicalPageURL = `${rootURL}/historical`
export const homePageURL = `${rootURL}/`
export const cryptoPageURL = `${rootURL}/crypto`
export const howtoPageURL = `${rootURL}/howto`
export const contactPageURL = `${rootURL}/contact`
export const termsPageURL = `${rootURL}/terms`
export const privacyPageURL = `${rootURL}/privacy`
export const cookiePageURL = `${rootURL}/cookies`
export const disclaimerPageURL = `${rootURL}/disclaimer`
export const sitemapURL = `${rootURL}/sitemap.xml`
export const refundPolicyURL = `${rootURL}/terms#refunds`